import { useState } from "react";
import {
    AppBar,
    Box,
    IconButton,
    Toolbar,
    Menu,
    MenuItem,
    Divider,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";

interface MyAppBarProps {
    title: string,
    user: any,
}

const MyAppBar: React.FC<MyAppBarProps> = ({ title, user }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="sticky"
                sx={{ bgcolor: '#fff5ee', color: '#000' }}
            >
                <Toolbar>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <Box sx={{ mr: "auto" }}>
                            <Link to="/">
                                <img
                                    src="assets/fbslogo.png"
                                    width={150}
                                    height={60}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Link>
                        </Box>

                        <Box sx={{ display: "flex", gap: 2 }}>
                            {user ? (
                                <>
                                    <IconButton color="inherit" onClick={handleMenuOpen}>
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleMenuClose}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        <MenuItem>
                                            <AccountCircleIcon sx={{ mr: 1 }} />
                                            <Box>
                                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>{user.name}</Typography>
                                                <Typography variant="body2" color="textSecondary">{user.email}</Typography>
                                            </Box>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem component={Link} to="/edit-profile" onClick={handleMenuClose}>Edit Profile</MenuItem>
                                        <MenuItem component={Link} to="/change-password" onClick={handleMenuClose}>Change Password</MenuItem>
                                        <Divider />
                                        <MenuItem component={Link} to="/logout" onClick={handleMenuClose}>Sign Out</MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <>
                                    <IconButton color="inherit" component={Link} to="/login">
                                        <LoginIcon />
                                    </IconButton>
                                    <IconButton color="inherit" component={Link} to="/register">
                                        <PersonAddIcon />
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default MyAppBar;
