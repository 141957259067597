import React from "react";
import { useHistory } from "react-router-dom";
import { 
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';
import HomeIcon from '@mui/icons-material/Home';
import { 
    Box,
    Button,
    Container,
    Grid,
} from '@mui/material';

import MyAppBar from "../components/MyAppBar";
import {
    CHANGE_PASSWORD_SUCCESS_NAME,
    CHANGE_PASSWORD_SUCCESS_TITLE,
    CHANGE_PASSWORD_SUCCESS_TEXT,
    HOME_NAME,
} from "../components/Global";
import "./PageEditPasswordSuccess.css";

interface PageEditPasswordSuccessProps {
    user: any,
}

const PageEditPasswordSuccess: React.FC<PageEditPasswordSuccessProps> = (props: PageEditPasswordSuccessProps) =>  {
    const history = useHistory();

    const handleClick = () => {
        history.push('/');
    }

    return (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={CHANGE_PASSWORD_SUCCESS_NAME}
                    user={props.user}
                />
            </IonHeader>
            
            <IonContent>
                <Box sx={{
                flexGrow: 1,
                margin: '32px',
                }}>
                    <Container maxWidth="sm">
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1>{CHANGE_PASSWORD_SUCCESS_TITLE}</h1>
                                    <>{CHANGE_PASSWORD_SUCCESS_TEXT}</>
                                </Grid>

                                <Box sx={{m: 1}} />

                                <Grid item xs={12}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleClick}
                                        startIcon={<HomeIcon />}
                                    >{HOME_NAME}</Button>
                                </Grid>

                            </Grid>
                        </form>
                    </Container>
                </Box>
            </IonContent>
        </IonPage>
    )
};

export default PageEditPasswordSuccess;