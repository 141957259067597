import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { 
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';
import LoginIcon from '@mui/icons-material/Login';
import { 
    Box,
    Button,
    Container,
    Grid,
} from '@mui/material';

import MyAppBar from "../components/MyAppBar";
import {
    REGISTER_SUCCESS_NAME,
    REGISTER_SUCCESS_TITLE,
    REGISTER_SUCCESS_TEXT,
    LOGIN_NAME,
} from "../components/Global";
import "./TabRegisterSuccess.css";
import { getUrl } from "../components/Urls";
import { getUrlParams } from "../components/Utils";

interface TabRegisterSuccessProps {

}

const TabRegisterSuccess: React.FC<TabRegisterSuccessProps> = (props: TabRegisterSuccessProps) =>  {
    const history = useHistory();

    // Use useLocation to access query string parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tenantHashid = queryParams.get('t');
    const samlRequest = queryParams.get('sso');
    const referrer = queryParams.get('referrer');

    // Init URL Params
    const params = getUrlParams(tenantHashid, samlRequest, referrer);
    
    // Init Register URL
    const loginUrl = new URL(getUrl('login', 'web'));
    loginUrl.search = new URLSearchParams(params).toString();

    const handleClick = () => {
        window.location.href = loginUrl.toString();
    }

    return (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={REGISTER_SUCCESS_NAME}
                    user={null}
                />
            </IonHeader>
            
            <IonContent>
                <Box sx={{
                flexGrow: 1,
                margin: '32px',
                }}>
                    <Container maxWidth="sm">
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1>{REGISTER_SUCCESS_TITLE}</h1>
                                    <>{REGISTER_SUCCESS_TEXT}</>
                                </Grid>

                                <Box sx={{m: 1}} />

                                <Grid item xs={12}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleClick}
                                        startIcon={<LoginIcon />}
                                    >{LOGIN_NAME}</Button>
                                </Grid>

                            </Grid>
                        </form>
                    </Container>
                </Box>
            </IonContent>
        </IonPage>
    )
};

export default TabRegisterSuccess;