import { IonPage, IonContent, IonHeader } from "@ionic/react";
import React from "react";
import { Helmet } from "react-helmet";
import { PolicyProps } from "./Terms";
import { HOME_NAME } from "../../components/Global";
import MyAppBar from "../../components/MyAppBar";

const Cancellation: React.FC<PolicyProps> = ({ user }) => {
    return (
        <IonPage>
            <Helmet>
                <title>Cancellation & Refund Policy</title>
            </Helmet>
            <IonHeader>
                <MyAppBar title={HOME_NAME} user={user} />
            </IonHeader>
            <IonContent>
                <div style={{
                    fontFamily: "Arial, sans-serif",
                    lineHeight: 1.6,
                    margin: 0,
                    padding: "20px",
                    backgroundColor: "#f8f8f8",
                    color: "#333"
                }}>
                    <div style={{
                        maxWidth: "900px",
                        margin: "auto",
                        background: "white",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
                    }}>
                        <h1 style={{ textAlign: "center", color: "#075E54" }}>CANCELLATION & REFUND POLICY</h1>
                        <p><strong>Effective Date:</strong> 19th March 2025</p>
                        <p>This Cancellation & Refund Policy ("Policy") is an integral part of the Terms & Conditions Agreement between the User ("Customer") and <strong>Finworld Solution Sdn Bhd</strong> ("Company"), governing the use of the Finworld E-Invoice software and related services.</p>
                        <p>By purchasing a subscription to Finworld E-Invoice, the Customer acknowledges, understands, and agrees to the terms of this Policy as stated below.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>1. NO CANCELLATION POLICY</h2>
                        <ul>
                            <li>All purchases of Finworld E-Invoice software are final and cannot be canceled once payment has been processed.</li>
                            <li>The Company does not provide order cancellations, regardless of usage, activation status, or changes in business requirements.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>2. NO REFUND POLICY</h2>
                        <ul>
                            <li>All payments made to the Company are final and non-refundable.</li>
                            <li>The Company does not issue refunds for any of the following reasons, including but not limited to:
                                <ul>
                                    <li>Change of mind after purchase.</li>
                                    <li>Business closure or operational changes.</li>
                                    <li>Non-usage or dissatisfaction with the software features.</li>
                                    <li>Incorrect purchases made by the User.</li>
                                    <li>Failure to meet business-specific needs.</li>
                                </ul>
                            </li>
                            <li>No partial refunds will be issued for unused periods of a subscription.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>3. TECHNICAL SUPPORT & ISSUE RESOLUTION</h2>
                        <ul>
                            <li>If the Customer encounters technical issues, login problems, or software access difficulties, the Company provides technical support.</li>
                            <li>Customers are required to contact the Company’s support team via email or helpline for assistance.</li>
                            <li>The Company will not issue refunds for technical difficulties that can be resolved through troubleshooting or updates.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>4. MODIFICATIONS TO THIS POLICY</h2>
                        <ul>
                            <li>The Company reserves the right to modify or update this Policy at any time without prior notice.</li>
                            <li>It is the Customer’s responsibility to regularly review the updated Policy on the Website.</li>
                            <li>Continued use of the Software after any updates constitutes acceptance of the revised terms.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>5. GOVERNING LAW & LEGAL ENFORCEMENT</h2>
                        <ul>
                            <li>This Policy shall be governed and construed in accordance with the laws of Malaysia.</li>
                            <li>Any disputes arising from this Policy shall first be resolved through negotiation. If unresolved, the dispute shall be referred to arbitration or legal proceedings in Malaysia.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>6. CONTACT INFORMATION</h2>
                        <p><strong>For any inquiries regarding this Policy, please contact:</strong></p>
                        <p>Email: <a href="mailto:contact@finworldtechnology.com">contact@finworldtechnology.com</a></p>
                        <p>Customer Service: +6019-4126726</p>

                        <p style={{ textAlign: "center", marginTop: "20px" }}>
                            <a href="/" style={{ color: "#128C7E", textDecoration: "none", fontWeight: "bold" }}>← Back to Home</a>
                        </p>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Cancellation;