import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import EmailValidator from "email-validator";
import LoginIcon from '@mui/icons-material/Login';
import {
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
} from '@mui/material';
import MyAppBar from "../components/MyAppBar";
import {
    VALIDATION_MESSAGES,
    LOGIN_NAME,
    FORGOT_PASSWORD_BUTTON,
    FORGOT_PASSWORD_NAME,
    HEADERS_JSON,
} from "../components/Global";
import { getUrl } from "../components/Urls";

import "./TabForgot.css";
import { getUrlParams } from "../components/Utils";

interface FormForgotProps {
    tenantHashid: string | null,
    samlRequest?: string | null,
    referrer?: string | null;
};

const FormForgot: React.FC<FormForgotProps> = ({ tenantHashid, samlRequest, referrer }) => {
    const history = useHistory();

    // Init URL Params
    const params = getUrlParams(tenantHashid, samlRequest, referrer);

    // Init Register URL
    const successUrl = new URL(getUrl('forgotSuccess', 'web'));
    successUrl.search = new URLSearchParams(params).toString();

    const [textFields, setTextFields] = useState({
        email: "",
    });

    const [inputErrors, setInputErrors] = useState<any>({
        email: false,
    });

    const [inputErrorMessages, setInputErrorMessages] = useState<any>({
        email: [VALIDATION_MESSAGES.email],
    });

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextFields({
            ...textFields,
            [e.target.name]: e.target.value,
        });
    };

    const handleButtonClick = () => {
        fetchForgot();
    }

    async function fetchForgot() {
        const url = getUrl('forgotPassword');

        const requestBody = {
            email: textFields.email,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: HEADERS_JSON,
            body: JSON.stringify(requestBody),
        });

        if (response.ok) {
            window.location.href = successUrl.toString();
        }
        else if (response.status === 422 || response.status === 429) {
            const { message, errors } = await response.json();

            const errorAttrs = Object.keys(errors);
            const errorStatuses = Object.fromEntries(errorAttrs.map((_, i) => [errorAttrs[i], true]));

            setInputErrors({
                ...inputErrors,
                ...errorStatuses,
            })
            setInputErrorMessages({
                ...inputErrorMessages,
                ...errors,
            });
        }
        else {
            console.log('error');
        }
    }

    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="sm">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={textFields.email}
                                error={inputErrors.email}
                                helperText={inputErrors.email ? inputErrorMessages.email.join('. ') : ''}
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = EmailValidator.validate(e.target.value);
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.email],
                                    });
                                }}
                            />
                        </Grid>

                        <Box sx={{ m: 1 }} />

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                disabled={!(EmailValidator.validate(textFields.email))}
                                onClick={handleButtonClick}
                            >{FORGOT_PASSWORD_BUTTON}</Button>
                        </Grid>

                        <Box sx={{ m: 2 }} />

                    </Grid>
                </form>
            </Container>

        </Box>
    );
};

interface TabForgotProps {
    user: any,
}

const TabForgot: React.FC<TabForgotProps> = (props: TabForgotProps) => {
    const history = useHistory();

    // Use useLocation to access query string parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tenantHashid = queryParams.get('t');
    const samlRequest = queryParams.get('sso');
    const referrer = queryParams.get('referrer');

    useEffect(() => {
        if (props.user !== null) {
            history.push('/');
        }
    }, [props.user]);

    return (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={FORGOT_PASSWORD_NAME}
                    user={null}
                />
            </IonHeader>

            <IonContent>
                <FormForgot tenantHashid={tenantHashid} samlRequest={samlRequest} referrer={referrer} />
            </IonContent>
        </IonPage>
    );

};

export default TabForgot;
