import React, { useState } from "react";
import { useParams } from "react-router-dom";

const accounts = [
    {
        phone: "60194126726",
        name: "Finworld E-Invoice",
        title: "E-Invoice",
    },
    {
        phone: "60135101393",
        name: "Finworld Stock",
        title: "Stock",
    },
];

const WhatsAppChatbot: React.FC = () => {
    const { phone } = useParams<{ phone: string }>();
    const account = accounts.find(acc => acc.phone === phone);
    const [message, setMessage] = useState("");

    const sendMessage = () => {
        if (message.trim() !== "") {
            const whatsappURL = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
            window.open(whatsappURL, "_blank");
        } else {
            alert("Please type a message");
        }
    };

    if (!account) {
        return <div>Account not found</div>;
    }

    return (
        <div>
            <div className="chat-widget" style={{ position: "fixed", top: "20px", left: "20px", width: "500px", background: "#fff", borderRadius: "10px", boxShadow: "0 4px 10px rgba(0,0,0,0.2)", overflow: "hidden", border: "1px solid #ddd" }}>
                <div className="chat-header" style={{ background: "#075E54", color: "white", padding: "15px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                    <img 
                        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" 
                        alt="User" 
                        style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "10px" }}
                    />
                    <div>
                        <strong>{account.name}</strong><br />
                        <small>{account.title}</small>
                    </div>
                </div>
                <div className="chat-body" style={{ background: "#ECE5DD", padding: "15px", height: "200px", overflowY: "auto" }}>
                    <div className="chat-message" style={{ background: "white", padding: "10px", borderRadius: "10px", maxWidth: "80%", fontSize: "14px", marginBottom: "10px" }}>
                        Send your {account.title} inquiry here, and I will reply to you shortly.
                    </div>
                </div>
                <div className="chat-footer" style={{ display: "flex", alignItems: "center", padding: "10px", borderTop: "1px solid #ddd", background: "#fff" }}>
                    <input 
                        type="text" 
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)} 
                        onKeyDown={(event) => event.key === "Enter" && sendMessage()} 
                        placeholder="Write your message" 
                        autoComplete="off"
                        style={{ flex: 1, padding: "5px", marginRight: "5px" }}
                    />
                    <button 
                        onClick={sendMessage} 
                        style={{ background: "#128C7E", color: "white", border: "none", padding: "8px 12px", cursor: "pointer" }}
                    >
                        &gt;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WhatsAppChatbot;
