import { IS_PRODUCTION } from "../components/Urls";

interface AppProps {
    name: string;
    website: string;
    whatsapp: string;
    facebook: string;
}

export const apps: AppProps[] = [
    {
        name: 'Finworld E-Invoice Services',
        website: IS_PRODUCTION ? 'https://einvoice.finworld.com' : 'https://localhost:8102',
        whatsapp: '60194126726',
        facebook: 'finworldsolutioneinvoice',
    },

    // {
    //     name: 'Finworld CRMS Services',
    //     website: IS_PRODUCTION ? 'https://crms.finworld.com' : 'https://localhost:8102',
    //     whatsapp: '60194126726',
    //     facebook: 'finworldsolutioneinvoice',
    // },

    {
        name: 'Finworld Stock Services',
        website: IS_PRODUCTION ? 'https://stock.finworld.com' : 'https://stock.test',
        whatsapp: '60135101393',
        facebook: 'finworldtechnology',
    },
];