import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EmailValidator from "email-validator";
import LoginIcon from '@mui/icons-material/Login';
import {
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
} from '@mui/material';
import {
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import { setAuth } from "../components/Auth";
import MyAppBar from "../components/MyAppBar";
import {
    LOGIN_NAME,
    REGISTER_NAME,
    FORGOT_PASSWORD_NAME,
    PASSWORD_MIN_LENGTH,
    HEADERS_JSON,
} from "../components/Global";
import { getUrl, getExtUrl } from "../components/Urls";

import { FormSsoHidden, SamlResponse } from "../pages/PageSso";

import "./TabLogin.css";
import { useVerifyTenant } from "../components/MultiTenantHelpers";
import { getUrlParams } from "../components/Utils";

interface FormLoginProps {
    tenantHashid: string | null,
    samlRequest: string | null,
    setToken: Function,
    setSamlResponse: Function,
    referrer?: string | null,
};

const FormLogin: React.FC<FormLoginProps> = (props: FormLoginProps) => {
    const history = useHistory();

    const BACKWARD_COMPAT_PASSWORD_MIN_LENGTH = 6;

    const [isError, setIsError] = useState<boolean>(false);
    const [authErrorMessage, setAuthErrorMessage] = useState("");

    const [samlRequest, setSamlRequest] = useState(props.samlRequest);

    const [textFields, setTextFields] = useState({
        email: "", //"mgmt@finworld.com.my",
        password: "", //"zEgp-s*f9MNP",
    });

    const [checkboxes, setCheckboxes] = useState({
        remember: true,
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextFields({
            ...textFields,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxes({
            ...checkboxes,
            [e.target.name]: e.target.checked,
        });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    const loginProcedure = () => {
        setIsError(false);
        setAuthErrorMessage("");
        fetchLogin();
    }

    const handleLoginButtonClick = () => {
        loginProcedure();
    }

    const handleInputKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && EmailValidator.validate(textFields.email) && textFields.password.length >= BACKWARD_COMPAT_PASSWORD_MIN_LENGTH) {
            loginProcedure();
        }
    }

    useEffect(() => {
        setSamlRequest(props.samlRequest);
    }, [props.samlRequest]);

    async function fetchLogin() {
        const { setToken, setSamlResponse } = props;
        const url = getUrl('login');

        const requestBody = {
            email: textFields.email,
            password: textFields.password,
            remember: checkboxes.remember,
            r: samlRequest,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: HEADERS_JSON,
            body: JSON.stringify(requestBody),
        });

        const { token, expires, saml, message, errors } = await response.json();

        if (response.ok) {
            if (saml) {
                setSamlResponse(saml);
            }

            setToken(token);
            setAuth(token, expires);
        }
        else if (response.status === 422) {
            setIsError(true);
            setAuthErrorMessage(message);
        }
        else if (response.status === 429) {
            setIsError(true);
            setAuthErrorMessage("Too many attempts. Please wait a while before trying");
        }
        else {
            setIsError(true);
            console.log('error', errors);
        }
    }

    // Init URL Params
    const params = getUrlParams(props.tenantHashid, props.samlRequest, props.referrer);

    // Init Register URL
    const registerUrl = new URL(getUrl('register', 'web'));
    registerUrl.search = new URLSearchParams(params).toString();

    // Init Forgot Password URL
    const forgotUrl = new URL(getUrl('forgot', 'web'));
    forgotUrl.search = new URLSearchParams(params).toString();

    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="sm">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={textFields.email}
                                error={isError}
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onKeyDown={handleInputKeyDown}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                value={textFields.password}
                                error={isError}
                                helperText={isError ? authErrorMessage : ''}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onKeyDown={handleInputKeyDown}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >{
                                                    showPassword ? <VisibilityOff /> : <Visibility />
                                                }</IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {/*<Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="remember"
                                        name="remember"
                                        checked={checkboxes.remember}
                                        onChange={handleChangeCheckbox}
                                        inputProps={{
                                            'aria-label': 'controlled',
                                        }}
                                    />
                                }
                                label="Remember Me"
                                labelPlacement="end"
                            />
                        </Grid>*/}

                        <Box sx={{ m: 1 }} />

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                disabled={!(EmailValidator.validate(textFields.email) && textFields.password.length >= BACKWARD_COMPAT_PASSWORD_MIN_LENGTH)}
                                onClick={handleLoginButtonClick}
                                startIcon={<LoginIcon />}
                            >{LOGIN_NAME}</Button>
                        </Grid>

                        <Box sx={{ m: 1 }} />

                        <Grid item xs={12}>
                            <Link
                                color="error"
                                href={forgotUrl.toString()}
                                underline="hover"
                            >{FORGOT_PASSWORD_NAME}</Link>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Box sx={{ m: 1 }} />

                        <Grid item xs={12}>
                            <Button
                                href={registerUrl.toString()}
                                color="success"
                                variant="contained"
                            >{REGISTER_NAME}</Button>
                        </Grid>

                        <Box sx={{ m: 1 }} />

                        {!props.referrer && (
                            <Grid item xs={12}>
                                <Button
                                    href={`${getExtUrl('stock')}?viewAs=guest`}
                                    color="success"
                                    variant="outlined"
                                >Browse Stock App as Guest</Button>
                            </Grid>)}
                    </Grid>
                </form>
            </Container>

        </Box>
    );
};

interface TabLoginProps {
    setTenantContext: Function,
    user: any,
    setToken: Function,
};

const TabLogin: React.FC<TabLoginProps> = (props: TabLoginProps) => {
    const queryParams = new URLSearchParams(window.location.search);

    const tenantHashid = queryParams.get("t");
    const agentHashid = queryParams.get("a");
    const samlRequest = queryParams.get("sso");
    const referrer = queryParams.get("referrer");

    const history = useHistory();

    // Init URL Params
    const params = getUrlParams(tenantHashid, samlRequest, referrer);

    // Init Register URL
    const loginUrl = new URL(getUrl('login', 'web'));
    loginUrl.search = new URLSearchParams(params).toString();

    const [samlResponse, setSamlResponse] = useState<SamlResponse | null>(null);

    const t = useVerifyTenant();

    console.log('samlResponse', samlResponse);

    useEffect(() => {
        if (t) {
            props.setTenantContext({
                tenant: t,
            });
        }
    }, [t]);

    useEffect(() => {
        if(props.user != null) {
            if(samlResponse != null) {
                window.location.href = referrer || '/';
            }
            else {
                window.location.href = '/';
            }
        }
    }, [props.user, samlResponse]);

    console.log('props.user', props.user);

    return samlResponse == null ? (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={LOGIN_NAME}
                    user={props.user}
                />
            </IonHeader>

            <IonContent>
                <FormLogin
                    tenantHashid={t?.hashid}
                    samlRequest={samlRequest}
                    setToken={props.setToken}
                    setSamlResponse={setSamlResponse}
                    referrer={referrer}
                />
            </IonContent>
        </IonPage>
    ) : (t ? (
        <FormSsoHidden
            tenant_hashid={t?.hashid}
            samlResponse={samlResponse}
            agentHashid={agentHashid}
            referrer={referrer}
        />
    ) : (
        <>
            <h1>Please REFRESH to proceed</h1>
        </>
    ));
};

export default TabLogin;