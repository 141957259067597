import React, {
    useEffect,
    useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';

import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PasswordStrengthBar from 'react-password-strength-bar';
import PasswordChecklist from "react-password-checklist";
import EmailValidator from "email-validator";

import { getUrl } from "../components/Urls";
import { getAuthHeader } from "../components/Auth";
import MyAppBar from "../components/MyAppBar";
import {
    VALIDATION_MESSAGES,
    REGISTER_NAME,
    PASSWORD_MIN_LENGTH,
    HEADERS_JSON,
} from "../components/Global";

import "./TabLogin.css";
import { getUrlParams } from "../components/Utils";

interface FormRegisterProps {
    tenantHashid: string | null,
    samlRequest?: string | null,
    referrer?: string | null;
}

const FormRegister: React.FC<FormRegisterProps> = ({ tenantHashid, samlRequest, referrer }) => {
    const history = useHistory();

    const [isEmailInteracted, setIsEmailInteracted] = useState(false);
    const [isPasswordInteracted, setIsPasswordInteracted] = useState(false);

    const [userAttrs, setUserAttrs] = useState({
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
        name: "",
    });

    const [inputErrors, setInputErrors] = useState<any>({
        email: false,
        email_confirmation: false,
        name: false,
    });

    const [inputErrorMessages, setInputErrorMessages] = useState<any>({
        email: [VALIDATION_MESSAGES.email],
        email_confirmation: [VALIDATION_MESSAGES.email_confirmation],
        name: [VALIDATION_MESSAGES.name],
    });

    const [emailsMatch, setEmailsMatch] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserAttrs({
            ...userAttrs,
            [e.target.name]: e.target.value,
        });

        if (e.target.name === "email_confirmation" || e.target.name === "email") {
            setEmailsMatch(userAttrs.email === e.target.value); // Check if emails match
        }
    };

    // Init URL Params
    const params = getUrlParams(tenantHashid, samlRequest, referrer);

    // Init Register URL
    const successUrl = new URL(getUrl('registerSuccess', 'web'));
    successUrl.search = new URLSearchParams(params).toString();

    async function fetchRegister() {
        const url = getUrl('register');

        const requestBody = userAttrs;

        const response = await fetch(url, {
            method: 'POST',
            headers: getAuthHeader(HEADERS_JSON),
            body: JSON.stringify(requestBody),
        });

        const { errors } = await response.json();

        if (response.ok) {
            window.location.href = successUrl.toString();
        }
        else if (response.status === 422) {
            const errorAttrs = Object.keys(errors);
            const errorStatuses = Object.fromEntries(errorAttrs.map((_, i) => [errorAttrs[i], true]));

            setInputErrors({
                ...inputErrors,
                ...errorStatuses,
            })
            setInputErrorMessages({
                ...inputErrorMessages,
                ...errors,
            });
        }
        else {
            console.log('error');
        }
    }

    const handleRegisterButtonClick = () => {
        fetchRegister();
    }

    const preventCopyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
    };

    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="sm">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={userAttrs.email}
                                error={inputErrors.email}
                                helperText={inputErrors.email ? inputErrorMessages.email.join('. ') : ''}
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = EmailValidator.validate(e.target.value);
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.email],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="email_confirmation"
                                name="email_confirmation"
                                label="Confirm Email"
                                type="email"
                                value={userAttrs.email_confirmation}
                                error={isEmailInteracted && !emailsMatch}
                                helperText={
                                    isEmailInteracted && !emailsMatch
                                        ? VALIDATION_MESSAGES.email_confirmation
                                        : ""
                                }
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onKeyDown={() => setIsEmailInteracted(true)}
                                onCut={preventCopyPaste}  // Prevent cut
                                onCopy={preventCopyPaste} // Prevent copy
                                onPaste={preventCopyPaste} // Prevent paste
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                value={userAttrs.name}
                                error={inputErrors.name}
                                helperText={inputErrors.name ? inputErrorMessages.name.join('. ') : ''}
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = e.target.value.length > 0;
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.name],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                value={userAttrs.password}
                                error={isPasswordInteracted && !passwordIsValid}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onKeyDown={(e) => {
                                    if (!isPasswordInteracted) setIsPasswordInteracted(true);
                                }}
                            />
                            <PasswordStrengthBar
                                password={userAttrs.password}
                                minLength={PASSWORD_MIN_LENGTH}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="password_confirmation"
                                name="password_confirmation"
                                label="Confirm Password"
                                type="password"
                                value={userAttrs.password_confirmation}
                                error={isPasswordInteracted && !passwordIsValid}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    if (!isPasswordInteracted) setIsPasswordInteracted(true);
                                }}
                                onCut={preventCopyPaste}  // Prevent cut
                                onCopy={preventCopyPaste} // Prevent copy
                                onPaste={preventCopyPaste} // Prevent paste
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PasswordChecklist
                                rules={[
                                    "minLength",
                                    //"specialChar",
                                    "number",
                                    "capital",
                                    "match",
                                ]}
                                minLength={PASSWORD_MIN_LENGTH}
                                value={userAttrs.password}
                                valueAgain={userAttrs.password_confirmation}
                                onChange={(isValid) => {
                                    setPasswordIsValid(isValid);
                                }}
                            />
                        </Grid>

                        <Box sx={{ m: 1 }} />

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                disabled={!passwordIsValid}
                                startIcon={<PersonAddIcon />}
                                onClick={handleRegisterButtonClick}
                            >{REGISTER_NAME}</Button>
                        </Grid>

                    </Grid>
                </form>
            </Container>

        </Box>
    );
};

interface TabRegisterProps {
    user: any,
}

const TabRegister: React.FC<TabRegisterProps> = (props: TabRegisterProps) => {
    const history = useHistory();

    // Use useLocation to access query string parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tenantHashid = queryParams.get('t');
    const samlRequest = queryParams.get('sso');
    const referrer = queryParams.get('referrer');

    useEffect(() => {
        if (props.user !== null) {
            history.push('/');
        }
    }, [props.user]);

    return (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={REGISTER_NAME}
                    user={props.user}
                />
            </IonHeader>

            <IonContent>
                <FormRegister tenantHashid={tenantHashid} samlRequest={samlRequest} referrer={referrer} />
            </IonContent>
        </IonPage>
    )
};

export default TabRegister;