import { IonPage, IonContent, IonHeader } from "@ionic/react";
import React from "react";
import { Helmet } from "react-helmet";
import { PolicyProps } from "./Terms";
import { HOME_NAME } from "../../components/Global";
import MyAppBar from "../../components/MyAppBar";

const Shipping: React.FC<PolicyProps> = ({ user }) => {
    return (
        <IonPage>
            <Helmet>
                <title>Shipping Policy</title>
            </Helmet>
            <IonHeader>
                <MyAppBar title={HOME_NAME} user={user} />
            </IonHeader>
            <IonContent>
                <div style={{
                    fontFamily: "Arial, sans-serif",
                    lineHeight: 1.6,
                    margin: 0,
                    padding: "20px",
                    backgroundColor: "#f8f8f8",
                    color: "#333"
                }}>
                    <div style={{
                        maxWidth: "900px",
                        margin: "auto",
                        background: "white",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
                    }}>
                        <h1 style={{ textAlign: "center", color: "#075E54" }}>SHIPPING POLICY</h1>
                        <p><strong>Effective Date:</strong> 19th March 2025</p>
                        <p>This Shipping Policy ("Policy") forms an integral part of the Terms & Conditions Agreement between the User ("Customer") and <strong>Finworld Solution Sdn Bhd</strong> ("Company"), governing the use of the Finworld E-Invoice software and related services.</p>
                        <p>By purchasing a subscription to Finworld E-Invoice, the Customer acknowledges, understands, and agrees to the terms outlined in this Policy.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>1. CLOUD-BASED SOFTWARE & NON-PHYSICAL DELIVERY</h2>
                        <ul>
                            <li>Finworld E-Invoice is a cloud-based software, and no physical product will be shipped to the Customer.</li>
                            <li>Access to the Software is provided digitally through the Company’s secure online platform.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>2. DELIVERY & ACCOUNT ACTIVATION</h2>
                        <ul>
                            <li>Upon successful payment, the Customer will receive login credentials and access details via the registered email address provided during the purchase.</li>
                            <li>The Company is not responsible for delays in access due to incorrect email addresses or spam filters blocking delivery.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>3. DELAYS & SUPPORT ASSISTANCE</h2>
                        <ul>
                            <li>While the Company strives for instant activation, delays may occur due to payment verification, system updates, or technical issues.</li>
                            <li>If the Customer does not receive their login credentials within the expected time frame, they must contact the Company's support team.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>4. MODIFICATIONS TO THIS POLICY</h2>
                        <p>The Company reserves the right to modify this Policy at any time without prior notice. Customers are responsible for reviewing updates.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>5. CONTACT INFORMATION</h2>
                        <p><strong>For any inquiries regarding this Policy, please contact:</strong></p>
                        <p>Email: <a href="mailto:contact@finworldtechnology.com">contact@finworldtechnology.com</a></p>
                        <p>Customer Service: +6019-4126726</p>

                        <p style={{ textAlign: "center", marginTop: "20px" }}>
                            <a href="/" style={{ color: "#128C7E", textDecoration: "none", fontWeight: "bold" }}>← Back to Home</a>
                        </p>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Shipping;
