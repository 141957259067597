import React, { useContext, useEffect, useState } from "react";
import { Route, useLocation } from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact,
} from '@ionic/react';
//import { IonTabsCustomEvent } from "@ionic/react-router";
import { IonReactRouter } from '@ionic/react-router';
import { home, logIn, logOut, personAddOutline } from 'ionicons/icons';

import PageSso from './pages/PageSso';

import TabHome from './pages/TabHome';
import TabLogin from './pages/TabLogin';
import TabLogout from './pages/TabLogout';
import TabRegister from './pages/TabRegister';
import TabRegisterSuccess from './pages/TabRegisterSuccess';
import TabForgot from './pages/TabForgot';
import TabForgotSuccess from './pages/TabForgotSuccess';
import TabReset from "./pages/TabReset";
import TabResetSuccess from "./pages/TabResetSuccess";
import { ThemeProvider } from '@mui/material/styles';
import PageEditProfile from './pages/PageEditProfile';
import PageEditProfileSuccess from './pages/PageEditProfileSuccess';
import PageEditPassword from './pages/PageEditPassword';
import PageEditPasswordSuccess from './pages/PageEditPasswordSuccess';
import { Helmet } from 'react-helmet';

import { getUrl } from "./components/Urls";
import { getAuthHeader } from "./components/Auth";

import {
    theme,
} from "./components/Global";

import {
    HOME_NAME,
    LOGIN_NAME,
    LOGOUT_NAME,
    REGISTER_NAME,
    HEADERS_JSON,
} from "./components/Global";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Roboto Font */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


/* Theme variables */
import './theme/variables.css';
import Welcome from "./pages/Welcome";
import WhatsAppChatbot from "./pages/WhatsApp/WhatsAppChatbot";
import Cancellation from "./pages/Policies/Cancellation";
import Privacy from "./pages/Policies/Privacy";
import Shipping from "./pages/Policies/Shipping";
import Terms from "./pages/Policies/Terms";

setupIonicReact();

export interface TenantProps {
    hashid: string,
    id: number,
};

interface TenantContextProps {
    tenant: TenantProps | null,
};

export const TenantContext = React.createContext<TenantContextProps>({
    tenant: null,
});

const App: React.FC = () => {
    const [tenantContext, setTenantContext] = useState<TenantContextProps>({
        tenant: null,
    });
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<any>(null);
    const [isFetched, setIsFetched] = useState(false);
    const [profileUpdated, setProfileUpdated] = useState<Date | null>(null);

    useEffect(() => {
        fetchUserProfileInfo();
    }, [token, profileUpdated]);


    async function fetchUserProfileInfo() {
        const url = getUrl('userProfileInfo');

        const response = await fetch(url, {
            method: 'GET',
            headers: getAuthHeader(HEADERS_JSON),
        });

        const { data, message } = await response.json();

        setIsFetched(true);

        if (response.ok) {
            setUser(data);
        }
        else {
            setUser(null);
            console.log('error', message);
        }
    }

    return (
        <TenantContext.Provider value={tenantContext}>
            <ThemeProvider theme={theme}>
                <IonApp>
                    <Helmet>
                        <title>Finworld Business Solutions</title>
                    </Helmet>
                    <IonReactRouter forceRefresh>
                        <IonRouterOutlet>
                            <Route exact path="/">
                                <Welcome
                                    user={user}
                                    isFetched={isFetched}
                                    setTenantContext={setTenantContext}
                                />
                            </Route>

                            <Route exact path="/whatsapp/:phone">
                                <WhatsAppChatbot />
                            </Route>

                            <Route path="/sso/:tenant_hashid/:samlRequest/:agent_hashid?" component={PageSso} />

                            <Route exact path="/login">
                                <TabLogin setTenantContext={setTenantContext} user={user} setToken={setToken} />
                            </Route>

                            <Route exact path="/logout">
                                <TabLogout user={user} setToken={setToken} />
                            </Route>

                            <Route exact path="/register">
                                <TabRegister user={user} />
                            </Route>
                            <Route exact path="/forgot">
                                <TabForgot user={user} />
                            </Route>
                            <Route exact path="/reset">
                                <TabReset />
                            </Route>

                            <Route exact path="/register-success">
                                <TabRegisterSuccess />
                            </Route>
                            <Route exact path="/forgot-success">
                                <TabForgotSuccess />
                            </Route>
                            <Route exact path="/reset-success">
                                <TabResetSuccess />
                            </Route>

                            {/* <Route exact path="/account">
                                <TabHome
                                    user={user}
                                    isFetched={isFetched}
                                    setTenantContext={setTenantContext}
                                />
                            </Route> */}

                            <Route exact path="/edit-profile">
                                <PageEditProfile
                                    user={user}
                                    isFetched={isFetched}
                                    setProfileUpdated={setProfileUpdated}
                                />
                            </Route>
                            <Route exact path="/edit-profile-success">
                                <PageEditProfileSuccess user={user} />
                            </Route>

                            <Route exact path="/change-password">
                                <PageEditPassword
                                    user={user}
                                    isFetched={isFetched}
                                />
                            </Route>
                            <Route exact path="/change-password-success">
                                <PageEditPasswordSuccess user={user} />
                            </Route>

                            <Route exact path="/terms">
                                <Terms user={user} />
                            </Route>

                            <Route exact path="/cancellation">
                                <Cancellation user={user} />
                            </Route>

                            <Route exact path="/shipping">
                                <Shipping user={user} />
                            </Route>

                            <Route exact path="/privacy">
                                <Privacy user={user} />
                            </Route>
                        </IonRouterOutlet>
                    </IonReactRouter>
                </IonApp>
            </ThemeProvider>
        </TenantContext.Provider>
    );
}

export default App;
