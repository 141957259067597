import { createTheme } from '@mui/material/styles';

export const SITE_NAME = 'Finworld Account';

export const HOME_NAME = 'Home';

export const LOGIN_NAME = 'Sign In';

export const LOGOUT_NAME = 'Sign Out';
export const LOGOUT_PROGRESS = 'Signing you out ...';
export const LOGOUT_SUCCESS = 'You have signed out successfully';

export const REGISTER_NAME = 'Create Account';
export const REGISTER_SUCCESS_NAME = 'Account Created';
export const REGISTER_SUCCESS_TITLE = 'Account Created Successfully';
export const REGISTER_SUCCESS_TEXT = (
    <>
        {/* <p>We have sent an email to the email address you specified. Kindly check your Inbox, including the Spam/Junk folder for our email.</p> */}
        {/* <p>{`You will need to verify your email address before signing in to ${SITE_NAME}`}</p> */}

        <p>{`You may now sign in to ${SITE_NAME}`}</p>
    </>
);

export const FORGOT_PASSWORD_NAME = 'Forgot Password';
export const FORGOT_PASSWORD_BUTTON = 'Request Password Reset Email';
export const FORGOT_SUCCESS_NAME = 'Password Reset Email Sent';
export const FORGOT_SUCCESS_TITLE = 'Password Reset Email Sent';
export const FORGOT_SUCCESS_TEXT = (
    <>
        <p>We have sent a password reset email to the address you specified.</p>
        <p>{`Kindly check your Inbox, including the Spam/Junk folder for the password reset email to reset your ${SITE_NAME} password.`}</p>
    </>
);

export const RESET_PASSWORD_NAME = 'Reset Password';
export const RESET_PASSWORD_BUTTON = 'Reset Password';
export const RESET_SUCCESS_NAME = 'Password Reset Successfully';
export const RESET_SUCCESS_TITLE = 'Password Reset Successfully';
export const RESET_SUCCESS_TEXT = (
    <>
        <p>Your password has been resetted successfully.</p>
        <p>{`Kindly sign in using your newly resetted ${SITE_NAME} password.`}</p>
    </>
);

export const PROFILE_INCOMPLETE_TITLE = 'Complete Your Profile';
export const PROFILE_INCOMPLETE_TEXT = (
    <>
        <p>{`Please complete your ${SITE_NAME} profile to continue...`}</p>
    </>
);
export const PROFILE_INCOMPLETE_BUTTON = 'Complete Profile';

export const EDIT_PROFILE_NAME = 'Edit Profile';
export const EDIT_PROFILE_COMPLETE_NAME = 'Complete Profile';
export const EDIT_PROFILE_SUCCESS_NAME = 'Profile Updated';
export const EDIT_PROFILE_SUCCESS_TITLE = 'Profile Updated Successfully';
export const EDIT_PROFILE_SUCCESS_TEXT = (
    <>
        <p>Your profile has been updated successfully.</p>
    </>
);

export const CHANGE_PASSWORD_NAME = 'Change Password';
export const CHANGE_PASSWORD_BUTTON = 'Change Password';
export const CHANGE_PASSWORD_SUCCESS_NAME = 'Password Changed';
export const CHANGE_PASSWORD_SUCCESS_TITLE = 'Password Changed Successfully';
export const CHANGE_PASSWORD_SUCCESS_TEXT = (
    <>
        <p>Your password has been changed successfully.</p>
        <p>{`Kindly sign in using your new ${SITE_NAME} password in future.`}</p>
    </>
);

export const PASSWORD_MIN_LENGTH = 8;

export const MIME_JSON = 'application/json';

export const HEADERS_JSON = {
    Accept: MIME_JSON,
    'Content-Type': MIME_JSON,
};

export const DATE_FORMAT_SHORT = 'yyyy-MM-dd';

export const VALIDATION_MESSAGES = {
    email: "A valid email is required",
    email_confirmation: "Email addresses do not match",
    name: "Name is required",

    current_password: "Current password is incorrect",

    nationality: "Nationality is required",
    national_id: "National ID is required for Malaysians",
    passport_issuer: "Passport Issuer is required for Non-Malaysians",
    passport_no: "Passport No. is required for Non-Malaysians",
    gender: "Gender is required",
    birth_date: "Birth Date is required",
    phone_mobile: "Phone (Mobile) is required",
    phone_home: "",
    phone_office: "",
    address: "Address is required",
    postcode: "Postcode is required. Enter a dash if not applicable",
    city: "City is required",
    state_my: "State is required",
    state: "State is required. Enter a dash if not applicable",
    country: "Country is required",

    status: "Status is required",
};

export const CKEY = 'h.P0UL>svdv%:y-7qXD~bn,e4N#zkr8A:.?1d_MvrPrUfsV*:AiXo1mVcaKG#6~';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#B8860B',
        },
        secondary: {
            main: '#FFD700',
        },
    },
});