import { IonPage, IonContent, IonHeader } from "@ionic/react";
import React from "react";
import { Helmet } from "react-helmet";
import { PolicyProps } from "./Terms";
import { HOME_NAME } from "../../components/Global";
import MyAppBar from "../../components/MyAppBar";

const Privacy: React.FC<PolicyProps> = ({ user }) => {
    return (
        <IonPage>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <IonHeader>
                <MyAppBar title={HOME_NAME} user={user} />
            </IonHeader>
            <IonContent>
                <div style={{
                    fontFamily: "Arial, sans-serif",
                    lineHeight: 1.6,
                    margin: 0,
                    padding: "20px",
                    backgroundColor: "#f8f8f8",
                    color: "#333"
                }}>
                    <div style={{
                        maxWidth: "900px",
                        margin: "auto",
                        background: "white",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
                    }}>
                        <h1 style={{ textAlign: "center", color: "#075E54" }}>PRIVACY POLICY</h1>
                        <p><strong>Effective Date:</strong> 19th March 2025</p>
                        <p>This Privacy Policy ("Policy") forms an integral part of the Terms & Conditions Agreement between <strong>Finworld Solution Sdn Bhd</strong> ("Company") and the User ("Customer"), governing the collection, use, storage, and protection of personal data in relation to the Finworld E-Invoice software and services.</p>
                        <p>By accessing or using the <a href="https://einvoice.finworld.com" target="_blank" rel="noopener noreferrer">Website</a> and Software, the User acknowledges, understands, and agrees to the terms outlined in this Policy.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>1. DATA COLLECTION</h2>
                        <ul>
                            <li>The Company collects basic user information necessary for account creation, software access, and invoicing.</li>
                            <li>Usage data may be tracked to improve system performance and security monitoring.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>2. DATA PROTECTION & SECURITY MEASURES</h2>
                        <ul>
                            <li>The Company implements strict security measures to protect User data.</li>
                            <li>Sensitive information is encrypted and securely processed via third-party providers.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>3. THIRD-PARTY DATA SHARING</h2>
                        <ul>
                            <li>The Company does not sell User data but may share it with regulatory authorities as required by law.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>4. USER RIGHTS & DATA CONTROL</h2>
                        <ul>
                            <li>Users can request access, correction, or deletion of their personal data.</li>
                            <li>Users may opt-out of non-essential marketing communications.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>5. RETENTION & DATA STORAGE</h2>
                        <ul>
                            <li>User data is retained as required by law and securely deleted when no longer needed.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>6. GOVERNING LAW & LEGAL COMPLIANCE</h2>
                        <p>This Policy is governed by the Personal Data Protection Act 2010 (PDPA) of Malaysia.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>7. CONTACT INFORMATION</h2>
                        <p><strong>For any inquiries regarding this Privacy Policy, please contact:</strong></p>
                        <p>Email: <a href="mailto:contact@finworldtechnology.com">contact@finworldtechnology.com</a></p>
                        <p>Customer Service: +6019-4126726</p>

                        <p style={{ textAlign: "center", marginTop: "20px" }}>
                            <a href="/" style={{ color: "#128C7E", textDecoration: "none", fontWeight: "bold" }}>← Back to Home</a>
                        </p>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Privacy;