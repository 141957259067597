import React, { 
    useEffect, 
    useState 
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { 
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';

import { 
    Box,
    Button,
    Container,
    Grid,
    TextField,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PasswordStrengthBar from 'react-password-strength-bar';
import PasswordChecklist from "react-password-checklist";
import EmailValidator from "email-validator";

import { getUrl } from "../components/Urls";
import { getAuthHeader } from "../components/Auth";
import MyAppBar from "../components/MyAppBar";
import {
    VALIDATION_MESSAGES,
    RESET_PASSWORD_BUTTON,
    RESET_PASSWORD_NAME,
    PASSWORD_MIN_LENGTH,
    HEADERS_JSON,
} from "../components/Global";

import "./TabLogin.css";

const FormReset: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const qEmail = params.get('email');
    const qToken = params.get('token');
    const isUrlValid = qEmail && qToken;

    const [isPasswordInteracted, setIsPasswordInteracted] = useState(false);

    const [inputAttrs, setInputAttrs] = useState({
        token: "",
        email: "",
        password: "",
        password_confirmation: "",
    });

    const [inputErrors, setInputErrors] = useState<any>({
        email: false,
    });

    const [inputErrorMessages, setInputErrorMessages] = useState<any>({
        email: [VALIDATION_MESSAGES.email],
    });

    const [passwordIsValid, setPasswordIsValid] = useState(false);

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputAttrs({
            ...inputAttrs,
            [e.target.name]: e.target.value,
        });
    };

    async function fetchReset() {
        const url = getUrl('resetPassword');

        const requestBody = inputAttrs;

        const response = await fetch(url, {
            method: 'POST',
            headers: HEADERS_JSON,
            body: JSON.stringify(requestBody),
        });

        if(response.ok) {
            history.push('/reset-success');
        }
        else if(response.status === 422 || response.status === 429) {
            const { errors } = await response.json();
            const errorAttrs = Object.keys(errors);
            const errorStatuses = Object.fromEntries(errorAttrs.map((_, i) => [errorAttrs[i], true]));
            
            setInputErrors({
                ...inputErrors,
                ...errorStatuses,
            })
            setInputErrorMessages({
                ...inputErrorMessages,
                ...errors,
            });
        }
        else {
            console.log('error');
        }
    };

    const handleButtonClick = () => {
        fetchReset();
    };

    useEffect(() => {
        setInputAttrs({
            ...inputAttrs,
            email: qEmail ? qEmail : '',
            token: qToken ? qToken : '',
        });
    }, []);


    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="sm">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={inputAttrs.email}
                                error={inputErrors.email}
                                helperText={inputErrors.email ? inputErrorMessages.email.join('. ') : ''}
                                disabled
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                value={inputAttrs.password}
                                error={isPasswordInteracted && !passwordIsValid}
                                disabled={!isUrlValid}
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    if(!isPasswordInteracted) setIsPasswordInteracted(true);
                                }}
                            />
                            <PasswordStrengthBar
                              password={inputAttrs.password}
                              minLength={PASSWORD_MIN_LENGTH}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="password_confirmation"
                                name="password_confirmation"
                                label="Confirm Password"
                                type="password"
                                value={inputAttrs.password_confirmation}
                                error={isPasswordInteracted && !passwordIsValid}
                                disabled={!isUrlValid}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    if(!isPasswordInteracted) setIsPasswordInteracted(true);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PasswordChecklist
                                rules={[
                                    "minLength",
                                    //"specialChar",
                                    "number",
                                    "capital",
                                    "match",
                                ]}
                                minLength={PASSWORD_MIN_LENGTH}
                                value={inputAttrs.password}
                                valueAgain={inputAttrs.password_confirmation}
                                onChange={(isValid) => {
                                    setPasswordIsValid(isValid);
                                }}
                            />
                        </Grid>

                        <Box sx={{m: 1}} />

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                disabled={!isUrlValid || !passwordIsValid}
                                onClick={handleButtonClick}
                            >{RESET_PASSWORD_BUTTON}</Button>
                        </Grid>
                        
                    </Grid>
                </form>
            </Container>
            
        </Box>
    );
};

interface TabResetProps {

}

const TabReset: React.FC<TabResetProps> = (props: TabResetProps) =>  {
    const history = useHistory();

    return (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={RESET_PASSWORD_NAME}
                    user={null}
                />
            </IonHeader>
            
            <IonContent>
                <FormReset />
            </IonContent>
        </IonPage>
    )
};

export default TabReset;