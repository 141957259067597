import React, { useState, useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import {
    IonContent,
    IonPage,
} from '@ionic/react';
import {
    HEADERS_JSON,
} from "../components/Global";
import { getAuth, getAuthHeader } from "../components/Auth";
import { getUrl } from "../components/Urls";
import MyProgress from "../components/MyProgress";

import "./PageSso.css";
import { getUrlParams } from "../components/Utils";

const DEBUG = false;

export interface SamlResponse {
    type: string,
    destination: string,
    SAMLResponse: string,
};

interface FormSsoHiddenProps {
    tenant_hashid: string,
    samlResponse: SamlResponse,
    agentHashid: string | null,
    referrer?: string | null,
};

export const FormSsoHidden: React.FC<FormSsoHiddenProps> = (props: FormSsoHiddenProps) => {
    const { tenant_hashid, samlResponse, agentHashid, referrer } = props;

    useEffect(() => {
        const buttonEl = document.getElementById('saml-sso-auto-click-button-id');
        if (buttonEl) {
            if(!DEBUG) buttonEl.click();
        }
    }, []);

    return (
        <>
            <form method="post" action={samlResponse.destination}>
                <input
                    type={DEBUG ? 'block' : 'hidden'}
                    id="tenant_hashid"
                    name="tenant_hashid"
                    defaultValue={tenant_hashid}
                />
                <input
                    type={DEBUG ? 'block' : 'hidden'}
                    id="SAMLResponse"
                    name="SAMLResponse"
                    defaultValue={samlResponse.SAMLResponse}
                />
                <input
                    type={DEBUG ? 'block' : 'hidden'}
                    id="agent_hashid"
                    name="agent_hashid"
                    defaultValue={agentHashid ? agentHashid : ''}
                />
                <input
                    type={DEBUG ? 'block' : 'hidden'}
                    id="referrer"
                    name="referrer"
                    defaultValue={referrer ? referrer : ''}
                />
                <input id="saml-sso-auto-click-button-id" type="submit" style={{ display: DEBUG ? 'block' : 'none' }} />
            </form>
        </>
    );
};

interface PageSsoProps extends RouteComponentProps<{
    tenant_hashid: string,
    samlRequest: string;
    agent_hashid?: string;
}> { }

const PageSso: React.FC<PageSsoProps> = ({ match }) => {
    const tenantHashid = decodeURIComponent(match.params.tenant_hashid);
    const samlRequest = decodeURIComponent(match.params.samlRequest);
    const agentHashid = match.params.agent_hashid ? decodeURIComponent(match.params.agent_hashid) : null;

    const [samlResponse, setSamlResponse] = useState<SamlResponse | null>(null);
    const [responseReceived, setResponseReceived] = useState(false);
    const [currentStatusText, setCurrentStatusText] = useState<string>('Authenticating ...');

    // Use useLocation to access query string parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referrer = queryParams.get('referrer');

    // Init URL Params
    const params = getUrlParams(tenantHashid, samlRequest, referrer);

    // Init Register URL
    const loginUrl = new URL(getUrl('login', 'web'));
    loginUrl.search = new URLSearchParams(params).toString();

    // console.log('tenantHashid', tenantHashid);
    // console.log('samlRequest', samlRequest);

    async function fetchSamlResponse() {
        const auth = getAuth();
        const url = getUrl('ssoResponse');

        const requestBody = {
            t: tenantHashid,
            r: samlRequest,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: auth ? getAuthHeader(HEADERS_JSON) : HEADERS_JSON,
            body: JSON.stringify(requestBody),
        });

        const { t, saml } = await response.json();
        // console.log('t', t);
        // console.log('saml', saml);

        setResponseReceived(true);

        if (response.ok) {
            //console.log('saml', saml);
            setSamlResponse(saml);
        }
        else if (response.status === 401) {
            window.location.href = loginUrl.toString();
        }
        else {
            setCurrentStatusText('Authentication Failed');
        }
    }

    useEffect(() => {
        fetchSamlResponse();
    }, []);

    return samlResponse ? (
        <FormSsoHidden
            tenant_hashid={tenantHashid}
            samlResponse={samlResponse}
            agentHashid={agentHashid}
            referrer={referrer}
        />
    ) : (
        <IonPage>
            <IonContent>
                <MyProgress text={currentStatusText} />
            </IonContent>
        </IonPage>
    );
};

export default PageSso;