import { IonPage, IonContent, IonHeader } from "@ionic/react";
import React from "react";
import { Helmet } from "react-helmet";
import { HOME_NAME } from "../../components/Global";
import MyAppBar from "../../components/MyAppBar";

export interface PolicyProps {
    user: any,
}

const Terms: React.FC<PolicyProps> = ({ user }) => {
    return (
        <IonPage>
            <Helmet>
                <title>Terms</title>
            </Helmet>
            <IonHeader>
                <MyAppBar title={HOME_NAME} user={user} />
            </IonHeader>
            <IonContent>
                <div style={{
                    fontFamily: "Arial, sans-serif",
                    lineHeight: 1.6,
                    margin: 0,
                    padding: "20px",
                    backgroundColor: "#f8f8f8",
                    color: "#333"
                }}>
                    <div style={{
                        maxWidth: "900px",
                        margin: "auto",
                        background: "white",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
                    }}>
                        <h1 style={{ textAlign: "center", color: "#075E54" }}>TERMS & CONDITIONS AGREEMENT</h1>
                        <p><strong>Effective Date:</strong> 19th March 2025</p>
                        <p>This Terms & Conditions Agreement ("Agreement") is entered into by and between <strong>Finworld Solution Sdn Bhd</strong> (Company No: [Insert Number]), a company incorporated and operating in Malaysia ("Company"), and the user ("Customer" or "User") who accesses, registers, or uses the Finworld E-Invoice software and services.</p>

                        <p>By accessing or registering as a Member of <a href="https://einvoice.finworld.com" target="_blank" rel="noopener noreferrer">https://einvoice.finworld.com</a> ("Website") or using the Finworld E-Invoice software ("Software"), the User agrees to be bound by the terms of this Agreement. If the User does not agree to these Terms & Conditions, they must immediately cease using the Website and Software.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>1. DEFINITIONS</h2>
                        <ul>
                            <li><strong>Company:</strong> Finworld Solution Sdn Bhd, including its affiliates, subsidiaries, directors, employees, and stakeholders.</li>
                            <li><strong>Software:</strong> Finworld E-Invoice, a cloud-based invoicing system offered by the Company.</li>
                            <li><strong>User:</strong> Any individual or business entity registering for or using the Software and Website.</li>
                            <li><strong>Website:</strong> <a href="https://einvoice.finworld.com" target="_blank" rel="noopener noreferrer">https://einvoice.finworld.com</a></li>
                            <li><strong>Agreement:</strong> This legally binding Terms & Conditions document.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>2. ACCEPTANCE OF TERMS & MEMBERSHIP</h2>
                        <ul>
                            <li>By registering as a Member of the Website, the User agrees to be bound by this Agreement.</li>
                            <li>Membership is only available to individuals aged 18 years or older.</li>
                            <li>The Company reserves the right to reject, suspend, or terminate any User’s membership at its sole discretion.</li>
                            <li>By registering, the User agrees to receive system notifications via email or other communication channels.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>3. SOFTWARE USAGE & LICENSE</h2>
                        <p>The Software is a cloud-based electronic invoicing solution provided on a subscription basis.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>4. PAYMENT, CANCELLATION & REFUND POLICY</h2>
                        <ul>
                            <li>All payments for the Finworld E-Invoice Software must be made in full before access is granted.</li>
                            <li><strong>No Cancellation:</strong> Once a purchase is made, it cannot be canceled under any circumstances.</li>
                            <li><strong>No Refunds:</strong> All payments made are final and non-refundable.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>5. DATA SECURITY & LIABILITY DISCLAIMER</h2>
                        <ul>
                            <li>The Company implements industry-standard security measures to protect User data.</li>
                            <li>The Company shall not be held responsible for any data loss, cyberattacks, or system downtime.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>6. SERVICE AVAILABILITY & SYSTEM DOWNTIME</h2>
                        <p>The Company strives to provide 99.9% uptime, but occasional downtime may occur due to maintenance or technical issues.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>7. COMPLIANCE & LEGAL OBLIGATIONS</h2>
                        <ul>
                            <li>Users are responsible for ensuring compliance with Malaysian tax laws.</li>
                            <li>The Company is not responsible for incorrect invoice submissions.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>8. LIMITATION OF LIABILITY</h2>
                        <ul>
                            <li>The Company is not liable for any financial, legal, or regulatory penalties due to User actions.</li>
                            <li>Any claims from third parties arising from User activities are the User's responsibility.</li>
                        </ul>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>9. CHANGES TO TERMS & CONDITIONS</h2>
                        <p>The Company reserves the right to modify or update this Agreement at any time without prior notice.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>10. GOVERNING LAW & DISPUTE RESOLUTION</h2>
                        <p>This Agreement shall be governed by the laws of Malaysia.</p>

                        <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px", color: "#075E54" }}>11. CONTACT INFORMATION</h2>
                        <p><strong>For any inquiries, please contact:</strong></p>
                        <p>Email: <a href="mailto:contact@finworldtechnology.com">contact@finworldtechnology.com</a></p>
                        <p>Customer Service: +6019-4126726</p>

                        <p style={{ textAlign: "center", marginTop: "20px" }}>
                            <a href="/" style={{ color: "#128C7E", textDecoration: "none", fontWeight: "bold" }}>← Back to Home</a>
                        </p>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Terms;
