import { TenantProps } from "../App";

export const IS_PRODUCTION = true;

// PRODUCTION
const API_SERVER_PROTOCOL = IS_PRODUCTION ? 'https' : 'https';
const API_SERVER_NAME = IS_PRODUCTION ? 'api-account.finworld.com' : 'account.test';
const API_PREFIX = IS_PRODUCTION ? 'api' : 'api';

const WEB_SERVER_PROTOCOL = IS_PRODUCTION ? 'https' : 'https';
const WEB_SERVER_NAME = IS_PRODUCTION ? 'account.finworld.com' : 'localhost:8100';
const WEB_SERVER_PREFIX = IS_PRODUCTION ? null : null;

//********************************************/

const API_URLS = {
    login: 'login',
    logout: 'logout',
    register: 'register',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',

    ssoResponse: 'sso',
    ssoLogoutSps: 'logout-request',

    userProfileInfo: 'user/profile-information',
    userPassword: 'user/password',

    // multi-tenant
    tenantVerify: 'tenants/verify',

    // admin
    adminServices: 'admin/services',

};

const WEB_URLS = {
    login: 'login',

    register: 'register',
    registerSuccess: 'register-success',

    forgot: 'forgot',
    forgotSuccess: 'forgot-success',
};

const EXT_URLS = {
    stock: IS_PRODUCTION ? 'https://stock.finworld.com' : 'https://stock.test',
    crm: 'https://localhost:8102',
    elearn: IS_PRODUCTION ? 'https://el.finworld.com' : 'https://localhost:8103',
    hrms: 'https://localhost:8104',
};

const URL_TYPES = {
    api: {
        protocol: API_SERVER_PROTOCOL,
        server: API_SERVER_NAME,
        prefix: API_PREFIX,
        urls: API_URLS,
    },
    web: {
        protocol: WEB_SERVER_PROTOCOL,
        server: WEB_SERVER_NAME,
        prefix: WEB_SERVER_PREFIX,
        urls: WEB_URLS,
    },
};


export function getUrl(key: keyof typeof API_URLS|keyof typeof WEB_URLS, urlType: keyof typeof URL_TYPES='api') {
    const type = URL_TYPES[urlType];

    let urlParts = [type.server];
    if(type.prefix !== null) urlParts.push(type.prefix);
    urlParts.push(type.urls[key as keyof typeof type.urls]);

    return `${type.protocol}://${urlParts.join('/')}`;
};

export function getExtUrl(key: keyof typeof EXT_URLS, tenant: TenantProps | null = null) {
    const qStr = tenant && tenant.hashid ? `?t=${tenant.hashid}` : '';
    return `${EXT_URLS[key]}${qStr}`;
};