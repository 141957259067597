import { IonPage, IonHeader, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonFooter, IonTitle, IonToolbar } from "@ionic/react";
import { useState, useEffect } from "react";
import { HOME_NAME } from "../components/Global";
import { useVerifyTenant } from "../components/MultiTenantHelpers";
import MyAppBar from "../components/MyAppBar";
import { apps } from "./WelcomeApps";
import React from "react";

interface WelcomeProps {
    user: any,
    isFetched: boolean,
    setTenantContext: Function,
};

const Welcome: React.FC<WelcomeProps> = ({ user, isFetched, setTenantContext }) => {
    const [activeTab, setActiveTab] = useState<{ [key: number]: string }>(() => {
        const defaultTabs: { [key: number]: string } = {};
        apps.forEach((_, index) => {
            defaultTabs[index] = 'website';
        });
        return defaultTabs;
    });

    const t = useVerifyTenant();

    useEffect(() => {
        if (t) {
            setTenantContext({
                tenant: t,
            });
        }
    }, [t]);

    const iframeStyle = {
        border: 'none',
        overflow: 'hidden',
    };

    const tabStyle = {
        textAlign: 'center',
        padding: '10px',
        fontWeight: 'bold',
        cursor: 'pointer',
    };

    const tabActiveStyle = {
        ...tabStyle,
        background: '#FFD700',
    };

    const tabInactiveStyle = {
        ...tabStyle,
        background: '#FFFFFF',
    };

    const footerStyles: { [key: string]: React.CSSProperties } = {
        footer: {
            backgroundColor: "#F8F1EB",
            padding: "10px 0",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderTop: "solid 1px #ccc",
        },
        footerContainer: {
            display: "flex",
            justifyContent: "center",  // Center everything
            alignItems: "center",
            width: "100%",
        },
        contentWrapper: {
            display: "flex",
            flexDirection: "row", // Ensure it stays in one row
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
        },
        linksContainer: {
            display: "flex",
            gap: "25px",
            flexWrap: "wrap",
            justifyContent: "center", // Center links inside the wrapper
        },
        link: {
            color: "#333",
            textDecoration: "none",
            fontSize: "16px",
            fontWeight: "bold",
        },
        linkHover: {
            textDecoration: "underline",
        },
        whatsappButton: {
            display: "flex",
            alignItems: "center",
            backgroundColor: "#25D366",
            color: "white",
            padding: "10px 15px",
            borderRadius: "25px",
            fontSize: "16px",
            fontWeight: "bold",
            textDecoration: "none",
            whiteSpace: "nowrap",
            marginLeft: "12px",
        },
        whatsappIcon: {
            width: "20px",
            height: "20px",
            marginRight: "8px",
            marginLeft: "8px"
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <MyAppBar title={HOME_NAME} user={user} />
            </IonHeader>
            <IonContent fullscreen>
                <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <IonGrid>
                        {apps.map((app, index) => {
                            return (
                                <React.Fragment key={`app-${index}`}>
                                    <h2 style={{ fontWeight: 'bold', marginLeft: '10px' }}>{app.name}</h2>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard style={{ border: '2px solid #FFD700' }}>
                                                <IonCardContent
                                                    onClick={(e) => {
                                                        if (activeTab[index] === 'website') {
                                                            e.stopPropagation(); // Prevents bubbling
                                                            window.location.href = app.website; // Opens in same tab
                                                        }
                                                    }}
                                                    style={{ cursor: activeTab[index] === 'website' ? 'pointer' : 'default' }}
                                                >
                                                    {activeTab[index] === 'website' && <div
                                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Ensures clicks don't propagate to iframe
                                                            window.location.href = app.website;
                                                        }}
                                                    ></div>}
                                                    <iframe
                                                        src={activeTab[index] === 'whatsapp' ? `/whatsapp/${app.whatsapp}` : app.website}
                                                        width="100%"
                                                        height="360"
                                                        style={iframeStyle}
                                                        allowFullScreen={true}
                                                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                    ></iframe>
                                                </IonCardContent>
                                                <IonRow>
                                                    <IonCol
                                                        style={activeTab[index] === 'website' ? tabActiveStyle : tabInactiveStyle}
                                                        onClick={() => setActiveTab(prev => ({ ...prev, [index]: 'website' }))}
                                                    >
                                                        Website
                                                    </IonCol>
                                                    <IonCol
                                                        style={activeTab[index] === 'whatsapp' ? tabActiveStyle : tabInactiveStyle}
                                                        onClick={() => setActiveTab(prev => ({ ...prev, [index]: 'whatsapp' }))}
                                                    >
                                                        WhatsApp
                                                    </IonCol>
                                                </IonRow>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard style={{ border: '2px solid #FFD700' }}>
                                                <IonCardContent>
                                                    <iframe
                                                        src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${app.facebook}&tabs=timeline&width=500&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true`}
                                                        width="500"
                                                        height="360"
                                                        style={iframeStyle}
                                                        allowFullScreen={true}
                                                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                    ></iframe>
                                                </IonCardContent>
                                                <IonRow>
                                                    <IonCol style={tabActiveStyle}>Facebook</IonCol>
                                                </IonRow>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow >
                                </React.Fragment>
                            );
                        })}
                    </IonGrid>
                </div>

                <IonToolbar style={footerStyles.footer}>
                    <div style={footerStyles.footerContainer}>
                        <div style={footerStyles.contentWrapper}>
                            {/* Hyperlinks */}
                            <div style={footerStyles.linksContainer}>
                                <a href="/terms" target="_blank" style={footerStyles.link}>Terms</a>
                                <a href="/cancellation" target="_blank" style={footerStyles.link}>Cancellation and Refund Policy</a>
                                <a href="/shipping" target="_blank" style={footerStyles.link}>Shipping Policy</a>
                                <a href="/privacy" target="_blank" style={footerStyles.link}>Privacy Policy</a>
                            </div>

                            {/* WhatsApp Button */}
                            <a href="https://wa.me/60194126726" target="_blank" style={footerStyles.whatsappButton}>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" style={footerStyles.whatsappIcon} />
                                WhatsApp Us
                            </a>
                        </div>
                    </div>
                </IonToolbar>
            </IonContent>
            <IonFooter>

            </IonFooter>
        </IonPage >
    );
};

export default Welcome;
