import React, { 
    useEffect, 
    useState 
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { 
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';

import { 
    Box,
    Button,
    Container,
    Grid,
    TextField,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PasswordStrengthBar from 'react-password-strength-bar';
import PasswordChecklist from "react-password-checklist";
import EmailValidator from "email-validator";

import { getUrl } from "../components/Urls";
import { getAuthHeader } from "../components/Auth";
import MyAppBar from "../components/MyAppBar";
import {
    VALIDATION_MESSAGES,
    CHANGE_PASSWORD_BUTTON,
    CHANGE_PASSWORD_NAME,
    PASSWORD_MIN_LENGTH,
    HEADERS_JSON,
} from "../components/Global";

import "./PageEditPassword.css";

const FormPassword: React.FC = () => {
    const history = useHistory();

    const [isPasswordInteracted, setIsPasswordInteracted] = useState(false);

    const [inputAttrs, setInputAttrs] = useState({
        current_password: "",
        password: "",
        password_confirmation: "",
    });

    const [inputErrors, setInputErrors] = useState<any>({
        current_password: false,
    });

    const [inputErrorMessages, setInputErrorMessages] = useState<any>({
        current_password: [VALIDATION_MESSAGES.current_password],
    });

    const [passwordIsValid, setPasswordIsValid] = useState(false);

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputAttrs({
            ...inputAttrs,
            [e.target.name]: e.target.value,
        });
    };

    async function fetchPassword() {
        const url = getUrl('userPassword');

        const requestBody = inputAttrs;

        const response = await fetch(url, {
            method: 'PUT',
            headers: getAuthHeader(HEADERS_JSON),
            body: JSON.stringify(requestBody),
        });

        if(response.ok) {
            history.push('/change-password-success');
        }
        else if(response.status === 422 || response.status === 429) {
            const { errors } = await response.json();
            const errorAttrs = Object.keys(errors);
            const errorStatuses = Object.fromEntries(errorAttrs.map((_, i) => [errorAttrs[i], true]));
            
            setInputErrors({
                ...inputErrors,
                ...errorStatuses,
            })
            setInputErrorMessages({
                ...inputErrorMessages,
                ...errors,
            });
        }
        else {
            console.log('error');
        }
    };

    const handleButtonClick = () => {
        fetchPassword();
    };


    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="sm">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="current_password"
                                name="current_password"
                                label="Current Password"
                                type="password"
                                value={inputAttrs.current_password}
                                error={inputErrors.current_password}
                                helperText={inputErrors.current_password ? inputErrorMessages.current_password.join('. ') : ''}
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = e.target.value.length >= PASSWORD_MIN_LENGTH;
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.current_password],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                name="password"
                                label="New Password"
                                type="password"
                                value={inputAttrs.password}
                                error={isPasswordInteracted && !passwordIsValid}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    if(!isPasswordInteracted) setIsPasswordInteracted(true);
                                }}
                            />
                            <PasswordStrengthBar
                              password={inputAttrs.password}
                              minLength={PASSWORD_MIN_LENGTH}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="password_confirmation"
                                name="password_confirmation"
                                label="Confirm New Password"
                                type="password"
                                value={inputAttrs.password_confirmation}
                                error={isPasswordInteracted && !passwordIsValid}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    if(!isPasswordInteracted) setIsPasswordInteracted(true);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PasswordChecklist
                                rules={[
                                    "minLength",
                                    //"specialChar",
                                    "number",
                                    "capital",
                                    "match",
                                ]}
                                minLength={PASSWORD_MIN_LENGTH}
                                value={inputAttrs.password}
                                valueAgain={inputAttrs.password_confirmation}
                                onChange={(isValid) => {
                                    setPasswordIsValid(isValid);
                                }}
                            />
                        </Grid>

                        <Box sx={{m: 1}} />

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                disabled={!passwordIsValid}
                                onClick={handleButtonClick}
                            >{CHANGE_PASSWORD_BUTTON}</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="outlined"
                                fullWidth
                                onClick={() => {history.goBack()}}
                            >Cancel</Button>
                        </Grid>
                        
                    </Grid>
                </form>
            </Container>
            
        </Box>
    );
};

interface PageEditPasswordProps {
    user: any,
    isFetched: boolean,
}

const PageEditPassword: React.FC<PageEditPasswordProps> = (props: PageEditPasswordProps) =>  {
    const history = useHistory();

    useEffect(() => {
        if(props.isFetched && props.user == null) {
            history.push('/login');
        }
    }, [props.isFetched, props.user]);

    return props.isFetched ? (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={CHANGE_PASSWORD_NAME}
                    user={props.user}
                />
            </IonHeader>
            
            <IonContent>
                <FormPassword />
            </IonContent>
        </IonPage>
    ) : null;
};

export default PageEditPassword;